import { System } from "@design-system/index"
import React, { useEffect } from "react"
import { useTable } from "@design-system/Table/hooks/useTable"
import classNames from "classnames"
import { useFormContext } from "react-hook-form"
import { CreateNotificationFormInputs } from "../../../types"

interface Props {
  item: {
    id: "emailChannel" | "slackChannel"
    channel: JSX.Element
    component: ({ item }: { item: any }) => JSX.Element
  }
}

export const StepChannelRow = ({ item }: Props) => {
  const {
    state: { expandedRows },
    toggleRowExpanded,
  } = useTable()

  const { setValue, watch } = useFormContext<CreateNotificationFormInputs>()

  const isOpen = watch(`channels.${item.id}.isOpen`)

  useEffect(() => {
    if (isOpen) {
      toggleRowExpanded({
        key: item.id,
      })
    }
  }, [])

  return (
    <System.Table.Row
      key={`row_${item.id}`}
      item={item}
      className="w-full flex-col divide-y divide-gray-50"
      isStacked
    >
      <div>
        <System.Table.InnerRowWithActions
          rowKey={item.id}
          item={item}
          className={classNames({
            "bg-indigo-50": expandedRows.has(item.id),
          })}
        >
          <div
            className="flex cursor-pointer items-center gap-8"
            onClick={() => {
              toggleRowExpanded({
                key: item.id,
              })
              setValue(`channels.${item.id}.isOpen`, !expandedRows.has(item.id))
            }}
          >
            <System.Button.Toggle active={expandedRows.has(item.id)} />
            {item.channel}
          </div>
        </System.Table.InnerRowWithActions>
      </div>
      {expandedRows.has(item.id) && (
        <System.Table.InnerRowChildren item={item}>
          {item.component}
        </System.Table.InnerRowChildren>
      )}
    </System.Table.Row>
  )
}
