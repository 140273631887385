import Config from "@environments/data"

/**
 * @returns https://private-api.wp-umbrella.com/notifications
 */
export const getPrivateApiAlertNotificationsPath = () => {
  return `${Config.private_api_url}/notifications`
}

/**
 * @returns https://private-api.wp-umbrella.com/notifications/:notificationId
 */
export const getPrivateApiAlertOneNotificationPath = ({
  notificationId,
}: {
  notificationId: string
}) => {
  return `${getPrivateApiAlertNotificationsPath()}/${notificationId}`
}

/**
 * @returns https://private-api.wp-umbrella.com/notifications/:notificationId/toggle
 */
export const getPrivateApiAlertToggleOneNotificationPath = ({
  notificationId,
}: {
  notificationId: string
}) => {
  return `${getPrivateApiAlertOneNotificationPath({
    notificationId,
  })}/toggle`
}

/**
 * @returns https://private-api.wp-umbrella.com/notifications/:notificationId
 */
export const getPrivateApiAlertLinkProjectsOneNotificationPath = ({
  notificationId,
}: {
  notificationId: string
}) => {
  return `${getPrivateApiAlertOneNotificationPath({
    notificationId,
  })}/projects`
}
