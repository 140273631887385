import { useContext } from "react"
import { System } from "@design-system/index"
import { TypeGenericActionGenericStep } from "../types"
import { get } from "lodash"

export function useGenericStep<TData = any>() {
  const context = useContext(System.Context.GenericStep.Context)

  if (!context) {
    throw new Error(
      "useGenericStep must be used within a System.Context.GenericStep.Provider",
    )
  }

  function setCurrentStep(payload: {
    key: string
    current: string
    data?: TData
  }) {
    context.dispatch({
      type: TypeGenericActionGenericStep.SET_CURRENT_STEP,
      payload: payload,
    })
  }

  function setCurrentData(payload: { key: string; data: TData }) {
    context.dispatch({
      type: TypeGenericActionGenericStep.SET_DATA_STEP,
      payload: payload,
    })
  }

  function getCurrentStep({ key }: { key: string }): string {
    return get(context.state.steps, [key, "current"])
  }

  function getCurrentData({ key }: { key: string }): TData {
    return get(context.state.steps, [key, "data"])
  }

  return {
    state: context.state,
    setCurrentStep: setCurrentStep,
    setCurrentData: setCurrentData,
    getCurrentStep: getCurrentStep,
    getCurrentData: getCurrentData,
  }
}
