import classNames from "classnames"

interface Props {
  children: React.ReactNode | string
  className?: string
}

const FieldInError = ({ children, className }: Props) => {
  return (
    <p
      className={classNames(className, "mt-1 text-sm font-medium text-red-500")}
    >
      {children}
    </p>
  )
}

export default FieldInError
