import { createSchema } from "morphism"

import { JSONAlertNotification } from "../types/alert-notification-models"
import { $Enums } from "@prisma/client"
import { hasBackupEvent } from "./functions/hasBackupEvent"
import { hasPerformanceEvent } from "./functions/hasPerformanceEvent"
import { hasSecurityEvent } from "./functions/hasSecurityEvent"
import { isNil } from "lodash"

export const SchemaJSONAlertNotificationApiDataToAlertNotificationModel =
  createSchema<
    Omit<
      AlertNotificationModel,
      | "hasBackupEvent"
      | "hasSecurityEvent"
      | "hasPerformanceEvent"
      | "hasSlackChannel"
      | "hasEmailChannel"
    >,
    JSONAlertNotification
  >({
    id: "id",
    name: "name",
    is_active: "is_active",
    emailNotificationChannelId: "emailNotificationChannelId",
    slackNotificationChannelId: "slackNotificationChannelId",
    eventTypes: "eventTypes",
    _count: "_count",
  })

class AlertNotificationModel implements JSONAlertNotification {
  _count: { projects: number }
  name: string
  id: string
  is_active: boolean
  emailNotificationChannelId?: string
  slackNotificationChannelId?: string
  eventTypes: $Enums.NotificationEventType[]

  hasEmailChannel(): boolean {
    return !isNil(this.emailNotificationChannelId)
  }

  hasSlackChannel(): boolean {
    return !isNil(this.slackNotificationChannelId)
  }

  hasBackupEvent(): boolean {
    return hasBackupEvent({
      eventTypes: this.eventTypes,
    })
  }

  hasSecurityEvent(): boolean {
    return hasSecurityEvent({
      eventTypes: this.eventTypes,
    })
  }

  hasPerformanceEvent(): boolean {
    return hasPerformanceEvent({
      eventTypes: this.eventTypes,
    })
  }
}

export default AlertNotificationModel
