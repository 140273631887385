import { DeleteAlertNotificationAction } from "./DeleteAlertNotification"
import { LinkSitesAction } from "./LinkSites"
import { ToggleAlertNotificationAction } from "./ToggleAlertNotification"
import { UnlinkSitesAction } from "./UnlinkSites"

export const NotifcationActionComponents = {
  ToggleAlertNotification: ToggleAlertNotificationAction,
  LinkSites: LinkSitesAction,
  UnlinkSites: UnlinkSitesAction,
  DeleteAlertNotification: DeleteAlertNotificationAction,
}
