import { System } from "@design-system/index"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"

interface Props {
  onConfirm: () => void
  notification: {
    name: string
  }
}

export const DeleteNotificationModal = ({ onConfirm }: Props) => {
  const { closeModal } = useGenericModal()

  return (
    <>
      <div className="text-center">
        <System.Title className="mb-2">Are you sure?</System.Title>
        <System.Text>
          By deleting the notification, you will no longer receive notifications
          associated with the following sites
        </System.Text>
      </div>
      <div className="mt-8 flex items-center gap-2">
        <System.Button.Outlined
          onClick={closeModal}
          className="w-full justify-center"
        >
          Cancel
        </System.Button.Outlined>
        <System.Button.Primary
          form="request-cache-clear"
          className="w-full justify-center"
          onClick={() => {
            onConfirm()
          }}
        >
          <System.Svg.Check className="h-4 w-4" />
          Delete Notification
        </System.Button.Primary>
      </div>
    </>
  )
}
