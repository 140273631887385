import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiAlertLinkProjectsOneNotificationPath } from "../api/routes-private"

interface Props {
  notificationId: string
  projectIds: number[]
}

export const unlinkSites = async ({ notificationId, projectIds }: Props) => {
  const headers = await getFetchPrivateHeaders()
  const result = await fetch(
    getPrivateApiAlertLinkProjectsOneNotificationPath({
      notificationId,
    }),
    {
      method: "DELETE",
      headers: headers,
      body: JSON.stringify({
        projectIds: projectIds,
      }),
    },
  )

  return await result.json()
}
