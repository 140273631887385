import { FetcherAlertingNotification } from "@/AlertingNotification/Fetcher"
import { SystemButtonTypes } from "@design-system/Button/types"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import { System } from "@design-system/index"
import useNotifications from "@hooks/notifications/useNotifications"
import { isNil } from "lodash"
import { mutate } from "swr"
import { NotificationComponents } from "../.."

interface Props {
  notification: {
    id: string
    name: string
  }
  onSuccess?: (props) => void
  mutateKey: string
  optimisticData?: (data) => any
  children?: React.ReactNode
  className?: string
  button?: SystemButtonTypes
}

export const DeleteAlertNotificationAction = ({
  onSuccess,
  mutateKey,
  optimisticData,
  notification,
  children,
  className = "",
  button = "Default",
}: Props) => {
  const Button = System.Button[button]

  const { openModal, closeModal } = useGenericModal()

  const { handleNotifySuccess } = useNotifications()

  const onConfirm = async () => {
    mutate(
      mutateKey,
      async (data) => {
        closeModal()

        const response = await FetcherAlertingNotification.deleteNotification({
          notificationId: notification.id,
        })

        handleNotifySuccess({
          title: "Notification deleted",
        })

        if (response.code === "success" && !isNil(onSuccess)) {
          onSuccess(response)
        }

        return optimisticData(data)
      },
      {
        optimisticData: optimisticData,
      },
    )
  }

  return (
    <>
      <Button
        className={className}
        onClick={() => {
          openModal({
            component: (
              <NotificationComponents.Modal.DeleteNotification
                onConfirm={onConfirm}
                notification={{
                  name: notification.name,
                }}
              />
            ),
          })
        }}
      >
        {children}
      </Button>
    </>
  )
}
