import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiAlertOneNotificationPath } from "../api/routes-private"

interface Props {
  notificationId: string
}

export const deleteNotification = async ({ notificationId }: Props) => {
  const headers = await getFetchPrivateHeaders()
  const result = await fetch(
    getPrivateApiAlertOneNotificationPath({
      notificationId,
    }),
    {
      method: "DELETE",
      headers: headers,
    },
  )

  return await result.json()
}
