import { FetcherAlertingNotification } from "@/AlertingNotification/Fetcher"
import { SystemButtonTypes } from "@design-system/Button/types"
import { System } from "@design-system/index"
import useNotifications from "@hooks/notifications/useNotifications"
import { isNil } from "lodash"
import { mutate } from "swr"

interface Props {
  notification: {
    id: string
    is_active: boolean
  }
  onSuccess?: (props) => void
  mutateKey: string
  optimisticData?: (data) => any
  children?: React.ReactNode
  className?: string
  button?: SystemButtonTypes
}

export const ToggleAlertNotificationAction = ({
  onSuccess,
  mutateKey,
  optimisticData,
  notification,
  children,
  className = "",
  button = "Toggle",
}: Props) => {
  const Button = System.Button[button]

  const { handleNotifySuccess } = useNotifications()

  const onClick = async () => {
    mutate(
      mutateKey,
      async (data) => {
        const response = await FetcherAlertingNotification.toggleNotification({
          notificationId: notification.id,
        })

        handleNotifySuccess({
          title: "Notification updated",
        })

        if (response.code === "success" && !isNil(onSuccess)) {
          onSuccess(response)
        }

        return optimisticData(data)
      },
      {
        optimisticData: optimisticData,
      },
    )
  }

  return (
    <>
      <Button
        className={className}
        onClick={onClick}
        active={notification.is_active}
      >
        {children}
      </Button>
    </>
  )
}
