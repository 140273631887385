import { NotificationEventType } from "@prisma/client"

export const hasSecurityEvent = ({
  eventTypes,
}: {
  eventTypes: NotificationEventType[]
}) => {
  const securityEvent = [
    NotificationEventType.VULNERABILITY_PLUGIN,
    NotificationEventType.VULNERABILITY_THEME,
    NotificationEventType.VULNERABILITY_WORDPRESS_CORE,
  ] as NotificationEventType[]

  return eventTypes.some((eventType) => securityEvent.includes(eventType))
}
