import { NotificationEventType } from "@prisma/client"

export const hasPerformanceEvent = ({
  eventTypes,
}: {
  eventTypes: NotificationEventType[]
}) => {
  const performanceEvent = [
    NotificationEventType.MONITORING_DOWNTIME,
    NotificationEventType.MONITORING_UPTIME,
  ] as NotificationEventType[]

  return eventTypes.some((eventType) => performanceEvent.includes(eventType))
}
