import { FetcherAlertingNotification } from "@/AlertingNotification/Fetcher"
import { SystemButtonTypes } from "@design-system/Button/types"
import { System } from "@design-system/index"
import useNotifications from "@hooks/notifications/useNotifications"
import { isNil } from "lodash"
import { useState } from "react"

interface Props {
  notification: {
    id: string
  }
  onSuccess?: (props) => void
  projectIds: number[]
  children?: React.ReactNode
  className?: string
  button?: SystemButtonTypes
}

export const LinkSitesAction = ({
  onSuccess,
  notification,
  projectIds,
  children,
  className = "",
  button = "Outlined",
}: Props) => {
  const Button = System.Button[button]

  const [loading, setLoading] = useState(false)

  const { handleNotifySuccess } = useNotifications()

  const onClick = async () => {
    setLoading(true)

    const response = await FetcherAlertingNotification.linkSites({
      notificationId: notification.id,
      projectIds: projectIds,
    })

    handleNotifySuccess({
      title: "Notification linked to sites",
    })

    if (response.code === "success" && !isNil(onSuccess)) {
      onSuccess(response)
    }

    setLoading(false)
  }

  return (
    <>
      <Button className={className} onClick={onClick} loading={loading}>
        {children}
      </Button>
    </>
  )
}
