import { System } from "@design-system/index"
import React from "react"
import { TableColumn } from "@design-system/Table/types"

import { JSONAlertNotification } from "@/AlertingNotification/types/alert-notification-models"
import classNames from "classnames"
import { NotificationComponents } from "@components/Notification/components"
import { mutate } from "swr"
import { getPrivateApiAlertNotificationsPath } from "@/AlertingNotification/api/routes-private"

interface Props {
  item: JSONAlertNotification
  columns?: Array<TableColumn>
  columnsClassname?: string
}

export const AlertNotificationRow = ({
  item,
  columns = [],
  columnsClassname,
}: Props) => {
  return (
    <System.Table.RowWithActions
      columns={columns}
      innerRowClassName={classNames(
        {
          "bg-indigo-50": item.is_active,
        },
        columnsClassname,
      )}
      rowKey={item.id}
      item={item}
    >
      <div className="flex items-center gap-8">
        <NotificationComponents.Action.ToggleAlertNotification
          notification={{
            id: item.id,
            is_active: item.is_active,
          }}
          mutateKey={getPrivateApiAlertNotificationsPath()}
          optimisticData={(data) => {
            return {
              ...data,
              data: data.data.map((notification) => {
                if (notification.id === item.id) {
                  return {
                    ...notification,
                    is_active: !item.is_active,
                  }
                }
                return notification
              }),
            }
          }}
          onSuccess={() => {
            mutate(getPrivateApiAlertNotificationsPath())
          }}
        />
        <System.Button.Default className="bg-white">
          <System.Svg.Alert className="h-4 w-4" />
          {item.name}
        </System.Button.Default>
      </div>
      <div className="flex items-center gap-2">
        <System.Text>
          <System.Strong>Alerts</System.Strong> enable for
        </System.Text>
        <NotificationComponents.ExistingNotification notification={item} />
      </div>

      <div className="ml-auto flex items-center gap-2">
        <System.Button.Default
          className="mr-2 !p-0 underline"
          href={`/notifications/${item.id}/sites-selector`}
        >
          {item._count.projects} site{item._count.projects > 1 && "s"}
        </System.Button.Default>
        <System.Button.Outlined href={`/notifications/${item.id}`}>
          <System.Svg.Edit className="h-4 w-4 opacity-50" />
          Edit
        </System.Button.Outlined>

        <NotificationComponents.Action.DeleteAlertNotification
          notification={{
            id: item.id,
            name: item.name,
          }}
          mutateKey={getPrivateApiAlertNotificationsPath()}
          optimisticData={(data) => {
            return {
              ...data,
              data: data.data.filter(
                (notification) => notification.id !== item.id,
              ),
            }
          }}
          onSuccess={() => {
            mutate(getPrivateApiAlertNotificationsPath())
          }}
        >
          <System.Svg.Trash className="h-4 w-4" />
        </NotificationComponents.Action.DeleteAlertNotification>
      </div>
    </System.Table.RowWithActions>
  )
}
