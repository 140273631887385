import { useFormContext } from "react-hook-form"
import { CreateNotificationFormInputs } from "../../types"
import { System } from "@design-system/index"
import React from "react"
import { useGenericStep } from "@design-system/Context/GenericStep/hooks/useGenericStep"
import { KEY_STEP_CREATE_NOTIFICATION_FORM } from "../../constants/step-create-notification"
import { StepChannelRow } from "./Row"
import { EmailChannel } from "./EmailChannel"
import classNames from "classnames"
import { SlackChannel } from "./SlackChannel"
import { AssignSelection } from "@prisma/client"

type Id = "emailChannel" | "slackChannel"

const ELEMENTS = [
  {
    id: "emailChannel" as Id,
    channel: (
      <>
        <div className="flex items-center gap-2">
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-white p-2">
            <System.Svg.Email />
          </div>
          <div>
            <System.Strong as="p">Email</System.Strong>
            <System.Text>Get notifications directly to your email.</System.Text>
          </div>
        </div>
      </>
    ),
    component: EmailChannel,
  },
  {
    id: "slackChannel" as Id,
    channel: (
      <div className="flex items-center gap-2">
        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-white p-2">
          <System.Svg.Slack />
        </div>
        <div>
          <System.Strong as="p">Slack</System.Strong>
          <System.Text>Receive alerts via Slack.</System.Text>
        </div>
      </div>
    ),
    component: SlackChannel,
  },
]

export const CreateNotifcationStepChannel = () => {
  const { setCurrentStep } = useGenericStep()

  const { getValues, watch } = useFormContext<CreateNotificationFormInputs>()
  const watchLinkSites = watch("site_assignment")
  const channels = watch("channels")

  const atLeastOneChannelSelected = Object.keys(channels).some(
    (key) => channels[key].isOpen,
  )

  return (
    <>
      <div className="mb-8 pl-4">
        <System.Title className="mb-2">
          Choose the platform you want to be alerted with
        </System.Title>
        <System.Text>Select how you'd like to receive alerts:</System.Text>
      </div>
      <System.Table.List
        columns={[]}
        rows={ELEMENTS}
        withExpanded={false}
        isStacked
      >
        {({ item }) => {
          return <StepChannelRow item={item} />
        }}
      </System.Table.List>
      <div className="ml-4 mt-8">
        <System.Title className="mb-2">Link websites</System.Title>
        <System.Text>Choose which sites these alerts apply to:</System.Text>

        <div className="mt-8 flex items-center">
          <div
            onClick={() => {}}
            className={classNames(
              {
                "border-gray-200 bg-indigo-50":
                  watchLinkSites === AssignSelection.ALL,
              },
              "flex min-w-64 items-start gap-4 rounded-l-lg border border-gray-100 p-4",
            )}
          >
            <System.Form.Input
              name="site_assignment"
              type="radio"
              id="radio-all"
              value={AssignSelection.ALL}
              withContext
            />
            <div className="-mt-2">
              <System.Form.Label htmlFor={`radio-all`} bold="light">
                <System.Strong>Apply to all websites</System.Strong>
                <System.Text>
                  Give acces to all existing and future websites of the
                  workspace
                </System.Text>
              </System.Form.Label>
            </div>
          </div>
          <div
            onClick={() => {}}
            className={classNames(
              {
                "border-gray-200 bg-indigo-50":
                  watchLinkSites === AssignSelection.CUSTOM,
              },
              "flex min-w-64 items-start gap-4 rounded-r-lg border border-l-0 border-gray-100 p-4",
            )}
          >
            <System.Form.Input
              name="site_assignment"
              type="radio"
              id="radio-custom"
              value={AssignSelection.CUSTOM}
              withContext
            />
            <div className="-mt-2">
              <System.Form.Label htmlFor={`radio-custom`} bold="light">
                <System.Strong>
                  Apply to custom selection of sites
                </System.Strong>
                <System.Text>
                  You will be able to define a custom selection of websites in
                  the next step.
                </System.Text>
              </System.Form.Label>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 flex items-center gap-2">
        <System.Button.Default
          onClick={() => {
            setCurrentStep({
              key: KEY_STEP_CREATE_NOTIFICATION_FORM,
              current: null,
              data: getValues(),
            })
          }}
        >
          <System.Svg.ChevronLeft className="h-4 w-4 opacity-50" />
          Go Back
        </System.Button.Default>
        <System.Form.Submit size="large" disabled={!atLeastOneChannelSelected}>
          {watchLinkSites === AssignSelection.ALL
            ? "Save"
            : "Save and Continue"}
          <System.Svg.Check className="h-4 w-4 opacity-50" />
        </System.Form.Submit>
      </div>
    </>
  )
}
