import { System } from "@design-system/index"
import React from "react"
import { useFormContext } from "react-hook-form"
import { CreateNotificationFormInputs } from "../../../types"
import FieldInError from "@components/Forms/components/FieldInError"

export const SlackChannel = () => {
  const {
    formState: { errors },
  } = useFormContext<CreateNotificationFormInputs>()

  return (
    <div>
      <div className="ml-4">
        <System.Title className="mb-2">Slack Alerts Webhook</System.Title>
        <System.Text>
          Setup a{" "}
          <System.ExternalLink
            size="small"
            href="https://my.slack.com/services/new/incoming-webhook/"
          >
            Slack webhook integration
          </System.ExternalLink>{" "}
          into your Slack team, then copy and paste the url here
        </System.Text>
      </div>

      <div className="mt-4">
        <System.Form.Input
          type="text"
          name="channels.slackChannel.webhook"
          placeholder="Slack Webhook URL"
          className="w-full"
          withContext
          registerOptions={{
            required: "This field is required",
          }}
        />
        {errors?.channels?.slackChannel?.webhook && (
          <FieldInError className="ml-4">
            {errors.channels.slackChannel?.webhook.message}
          </FieldInError>
        )}
      </div>
    </div>
  )
}
