import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiAlertOneNotificationPath } from "../api/routes-private"
import { CreateNotificationFormInputs } from "@components/Notification/components/Form/types"

interface Props {
  notificationId: string
  data: CreateNotificationFormInputs
}

export const editNotification = async (props: Props) => {
  const headers = await getFetchPrivateHeaders()
  const result = await fetch(
    getPrivateApiAlertOneNotificationPath({
      notificationId: props.notificationId,
    }),
    {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(props.data),
    },
  )

  return await result.json()
}
