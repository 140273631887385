import { isEmpty, isNil } from "lodash"
import { morphism } from "morphism"
import useSWR from "swr"
import { getPrivateApiAlertOneNotificationPath } from "@/AlertingNotification/api/routes-private"
import fetcherPrivateApiUmbrella from "@helpers/fetcherPrivateApiUmbrella"
import OneAlertNotificationModel, {
  SchemaJSONOneAlertNotificationApiDataToAlertNotificationModel,
} from "@/AlertingNotification/models/OneAlertNotificationModel"
import { JSONOneAlertNotification } from "@/AlertingNotification/types/alert-notification-models"

interface Props {
  notificationId: string
}

export const useOneAlertingNotification = ({ notificationId }: Props) => {
  const { data: response, ...rest } = useSWR<{
    data: JSONOneAlertNotification
    code: string
  }>(
    getPrivateApiAlertOneNotificationPath({
      notificationId: notificationId,
    }),
    {
      suspense: true,
      fetcher: fetcherPrivateApiUmbrella(),
    },
  )

  const { code, data } = response || {}

  if (isNil(data) || isEmpty(data) || code !== "success") {
    return {
      data: null,
      ...rest,
    }
  }

  return {
    data: morphism(
      SchemaJSONOneAlertNotificationApiDataToAlertNotificationModel,
      data,
      OneAlertNotificationModel,
    ),
    ...rest,
  }
}
