import React from "react"
import { useOneAlertingNotification } from "@components/Notification/hooks/useOneAlertingNotification"
import OneAlertNotificationModel from "@/AlertingNotification/models/OneAlertNotificationModel"

interface Props {
  children: (props: {
    notification: OneAlertNotificationModel
  }) => React.ReactNode
  isImmutable?: boolean
  notificationId: string
}

export function OneNotification({ children, notificationId }: Props) {
  const { data } = useOneAlertingNotification({
    notificationId,
  })

  return (
    <>
      {children({
        notification: data,
      })}
    </>
  )
}
