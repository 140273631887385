import {
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext,
} from "react-hook-form"
import { CreateNotificationFormInputs } from "../types"
import React from "react"
import { useGenericStep } from "@design-system/Context/GenericStep/hooks/useGenericStep"
import {
  KEY_STEP_CREATE_NOTIFICATION_FORM,
  STEP_CHOOSE_CHANNEL,
} from "../constants/step-create-notification"
import { CreateNotifcationStepEventTypes } from "./StepEventTypes"
import { isNil } from "lodash"
import { CreateNotifcationStepChannel } from "./StepChannel"
import { AssignSelection } from "@prisma/client"

export const CreateNotifcationFormProvider = ({
  children,
  defaultValues = {
    name: "",
    eventTypes: [],
    channels: {
      emailChannel: {
        isOpen: false,
        emails: [],
      },
      slackChannel: {
        isOpen: false,
        webhook: "",
      },
    },
    site_assignment: AssignSelection.ALL,
  },
}: {
  children: React.ReactNode
  defaultValues?: CreateNotificationFormInputs
}) => {
  const methods = useForm<CreateNotificationFormInputs>({
    defaultValues: defaultValues,
  })

  return <FormProvider {...methods}>{children}</FormProvider>
}

interface Props {
  id?: string
  onSubmit: SubmitHandler<CreateNotificationFormInputs>
}

export const CreateNotifcationForm = ({
  id = "create-notifcation",
  onSubmit,
}: Props) => {
  const { getCurrentStep } = useGenericStep()
  const currentStep = getCurrentStep({
    key: KEY_STEP_CREATE_NOTIFICATION_FORM,
  })

  const { handleSubmit } = useFormContext<CreateNotificationFormInputs>()

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      {isNil(currentStep) && <CreateNotifcationStepEventTypes />}
      {currentStep === STEP_CHOOSE_CHANNEL && <CreateNotifcationStepChannel />}
    </form>
  )
}

export default {
  Provider: CreateNotifcationFormProvider,
  Form: CreateNotifcationForm,
}
