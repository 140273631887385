import { System } from "@design-system/index"
import { useGenericModal } from "@design-system/Context/GenericModal/hooks/useGenericModal"
import { useState } from "react"

interface Props {
  onConfirm: () => void
  notification: {
    name: string
  }
  totalProjects: number
}

export const ConfirmLinkNotificationToSites = ({
  onConfirm,
  notification,
  totalProjects,
}: Props) => {
  const { closeModal } = useGenericModal()
  const [loading, setLoading] = useState(false)

  return (
    <>
      <div className="text-center">
        <System.Title className="mb-2">Are you sure?</System.Title>
        <System.Text>
          Are you sure you want to assign{" "}
          <System.Strong>{notification.name}</System.Strong> to the selected
          site{totalProjects > 1 && "s"}?
        </System.Text>
      </div>
      <div className="mt-8 flex items-center gap-2">
        <System.Button.Outlined
          onClick={closeModal}
          className="w-full justify-center"
        >
          Cancel
        </System.Button.Outlined>
        <System.Button.Primary
          form="request-cache-clear"
          className="w-full justify-center"
          loading={loading}
          onClick={() => {
            setLoading(true)
            onConfirm()
          }}
        >
          <System.Svg.Check className="h-4 w-4" />
          Assign Selected Sites
        </System.Button.Primary>
      </div>
    </>
  )
}
