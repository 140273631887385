import { getTitleNotificationEventType } from "@/AlertingNotification/models/functions/getTitleNotificationEventType"
import { hasBackupEvent } from "@/AlertingNotification/models/functions/hasBackupEvent"
import { hasPerformanceEvent } from "@/AlertingNotification/models/functions/hasPerformanceEvent"
import { hasSecurityEvent } from "@/AlertingNotification/models/functions/hasSecurityEvent"
import {
  JSONAlertNotification,
  JSONOneAlertNotification,
} from "@/AlertingNotification/types/alert-notification-models"
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@design-system/Shadcn/ui/tooltip"
import { System } from "@design-system/index"
import { NotificationEventType } from "@prisma/client"

interface Props {
  notification: JSONOneAlertNotification | JSONAlertNotification
}

export const ExistingNotification = ({ notification }: Props) => {
  return (
    <div className="flex items-center gap-1">
      {notification.hasSlackChannel() && (
        <div className="rounded-full bg-gray-900 p-1">
          <System.Svg.SlackUncolor className="h-7 w-7 text-white" />
        </div>
      )}
      {notification.hasEmailChannel() && (
        <div className="rounded-full bg-indigo-900">
          <System.Svg.Email
            className="h-7 w-7 p-1 text-white"
            strokeWidth={1.5}
          />
        </div>
      )}
      <System.Svg.ChevronRight className="h-4 w-4 text-gray-600 opacity-50" />
      <div className="flex items-center gap-2 rounded-full border bg-white px-2">
        {notification.hasBackupEvent() && (
          <>
            <TooltipProvider delayDuration={0}>
              <Tooltip>
                <TooltipTrigger className="cursor-default">
                  <System.Svg.Backup className="h-7 w-7 p-1" />
                </TooltipTrigger>
                <TooltipContent className="bg-slate-700 text-slate-50">
                  <p>
                    {notification.eventTypes.map(
                      (eventType: NotificationEventType, key) => {
                        if (
                          !hasBackupEvent({
                            eventTypes: [eventType],
                          })
                        ) {
                          return null
                        }
                        return (
                          <div key={`${eventType}_${key}`}>
                            {getTitleNotificationEventType(eventType)}
                          </div>
                        )
                      },
                    )}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </>
        )}
        {notification.hasPerformanceEvent() && (
          <>
            <TooltipProvider delayDuration={0}>
              <Tooltip>
                <TooltipTrigger className="cursor-default">
                  <System.Svg.Monitoring className="h-7 w-7 p-1" />
                </TooltipTrigger>
                <TooltipContent className="bg-slate-700 text-slate-50">
                  <p>
                    {notification.eventTypes.map(
                      (eventType: NotificationEventType, key) => {
                        if (
                          !hasPerformanceEvent({
                            eventTypes: [eventType],
                          })
                        ) {
                          return null
                        }
                        return (
                          <div key={`${eventType}_${key}`}>
                            {getTitleNotificationEventType(eventType)}
                          </div>
                        )
                      },
                    )}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </>
        )}

        {notification.hasSecurityEvent() && (
          <>
            <TooltipProvider delayDuration={0}>
              <Tooltip>
                <TooltipTrigger className="cursor-default">
                  <System.Svg.Shield className="h-7 w-7 p-1" />
                </TooltipTrigger>
                <TooltipContent className="bg-slate-700 text-slate-50">
                  <p>
                    {notification.eventTypes.map(
                      (eventType: NotificationEventType, key) => {
                        if (
                          !hasSecurityEvent({
                            eventTypes: [eventType],
                          })
                        ) {
                          return null
                        }
                        return (
                          <div key={`${eventType}_${key}`}>
                            {getTitleNotificationEventType(eventType)}
                          </div>
                        )
                      },
                    )}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </>
        )}
      </div>
    </div>
  )
}
