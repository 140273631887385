import React from "react"
import { useAlertingNotifications } from "@components/Notification/hooks/useAlertingNotifications"
import AlertNotificationModel from "@/AlertingNotification/models/AlertNotificationModel"

interface Props {
  children: (props: {
    notifications: AlertNotificationModel[]
  }) => React.ReactNode
  isImmutable?: boolean
}

export function AllNotifications({ children }: Props) {
  const { data } = useAlertingNotifications()

  return (
    <>
      {children({
        notifications: data,
      })}
    </>
  )
}
