import { NotificationEventType } from "@prisma/client"

export const getTitleNotificationEventType = (
  eventType: NotificationEventType,
) => {
  switch (eventType) {
    case NotificationEventType.BACKUP_DOWNLOADABLE:
      return "Backup Downloadable"
    case NotificationEventType.BACKUP_FAILED:
      return "Backup Failed"
    case NotificationEventType.RESTORATION_FAILED:
      return "Restoration Failed"
    case NotificationEventType.RESTORATION_SUCCESS:
      return "Restoration Success"
    case NotificationEventType.MONITORING_DOWNTIME:
      return "Website Down"
    case NotificationEventType.MONITORING_UPTIME:
      return "Uptime Confirmation"
    case NotificationEventType.VULNERABILITY_PLUGIN:
      return "Vulnerability Plugin"
    case NotificationEventType.VULNERABILITY_THEME:
      return "Vulnerability Theme"
    case NotificationEventType.VULNERABILITY_WORDPRESS_CORE:
      return "Vulnerability WordPress Core"
    default:
      return ""
  }
}
