import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiAlertNotificationsPath } from "../api/routes-private"
import { CreateNotificationFormInputs } from "@components/Notification/components/Form/types"

interface Props {
  data: CreateNotificationFormInputs
}

export const createNotification = async (props: Props) => {
  const headers = await getFetchPrivateHeaders()
  const result = await fetch(getPrivateApiAlertNotificationsPath(), {
    method: "POST",
    headers: headers,
    body: JSON.stringify(props.data),
  })

  return await result.json()
}
