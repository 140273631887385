import { getPrivateApiAlertNotificationsPath } from "@/AlertingNotification/api/routes-private"
import AlertNotificationModel, {
  SchemaJSONAlertNotificationApiDataToAlertNotificationModel,
} from "@/AlertingNotification/models/AlertNotificationModel"
import { JSONAlertNotification } from "@/AlertingNotification/types/alert-notification-models"
import fetcherPrivateApiUmbrella from "@helpers/fetcherPrivateApiUmbrella"
import { isEmpty, isNil } from "lodash"
import { morphism } from "morphism"
import useSWR from "swr"

export const useAlertingNotifications = () => {
  const { data: response, ...rest } = useSWR<{
    data: JSONAlertNotification[]
    code: string
  }>(getPrivateApiAlertNotificationsPath(), {
    suspense: true,
    fetcher: fetcherPrivateApiUmbrella(),
  })

  const { code, data } = response || {}

  if (isNil(data) || isEmpty(data) || code !== "success") {
    return {
      data: [] as AlertNotificationModel[],
      ...rest,
    }
  }

  return {
    data: morphism(
      SchemaJSONAlertNotificationApiDataToAlertNotificationModel,
      data,
      AlertNotificationModel,
    ),
    ...rest,
  }
}
