import { useFormContext } from "react-hook-form"
import { CreateNotificationFormInputs } from "../../types"
import { System } from "@design-system/index"
import { NotificationEventType } from "@prisma/client"
import React from "react"
import { useGenericStep } from "@design-system/Context/GenericStep/hooks/useGenericStep"
import {
  KEY_STEP_CREATE_NOTIFICATION_FORM,
  STEP_CHOOSE_CHANNEL,
} from "../../constants/step-create-notification"
import FieldInError from "@components/Forms/components/FieldInError"
import { isEmpty } from "lodash"

const ELEMENTS = {
  MONITORING: [
    {
      value: NotificationEventType.MONITORING_DOWNTIME,
      label: "Downtime notification",
      description: "Inform me when a website becomes inaccessible.",
    },
    {
      value: NotificationEventType.MONITORING_UPTIME,
      label: "Uptime Confirmation",
      description: "Inform me when the website becomes accessible again.",
    },
    // {
    //     value: NotificationEventType.MONITORING_VISUAL_REGRESSION,
    //     label: "Visual Breaks",
    //     description: (
    //         <>
    //             Inform me when there are visual changes on my websites.
    //             <br />
    //             <System.Svg.AlertTriangle className="inline w-4 h-4 mr-1 text-yellow-600" />
    //             You need to activate the visual regression monitoring to be
    //             alerted.
    //         </>
    //     ),
    // },
  ],
  SECURITY: [
    {
      value: NotificationEventType.VULNERABILITY_PLUGIN,
      label: "Plugin vulnerabilities",
      description:
        "Inform me when a plugin vulnerability is discovered on my website.",
    },
    {
      value: NotificationEventType.VULNERABILITY_THEME,
      label: "Theme vulnerabilities",
      description:
        "Inform me when a theme vulnerability is discovered on my website.",
    },
    {
      value: NotificationEventType.VULNERABILITY_WORDPRESS_CORE,
      label: "WordPress core vulnerabilities",
      description:
        "Inform me when a WordPress vulnerability is discovered on my website.",
    },
  ],
  BACKUP: [
    {
      value: NotificationEventType.BACKUP_DOWNLOADABLE,
      label: "Backup ready for download",
      description: "Inform me when a new backup is available for download.",
    },
    {
      value: NotificationEventType.RESTORATION_FAILED,
      label: "Failed restoration",
      description: "Alert me if a site restoration fails.",
    },
    {
      value: NotificationEventType.RESTORATION_SUCCESS,
      label: "Successful restoration",
      description: "Inform me of successful site restorations.",
    },
  ],
  PLUGIN: [
    {
      value: NotificationEventType.SAFE_UPDATE_FAILED,
      label: "Safe Update Failed",
      description: "Inform me when a safe update to the website fails.",
    },
  ],
}

const atLeastOneChecked = (value) => {
  return (
    (Array.isArray(value) && value.some((v) => v)) ||
    "Please select at least one event type"
  )
}

export const CreateNotifcationStepEventTypes = () => {
  const { setCurrentStep } = useGenericStep()

  const {
    getValues,
    trigger,
    formState: { errors },
  } = useFormContext<CreateNotificationFormInputs>()

  return (
    <>
      <div className="w-1/2 space-y-2">
        <System.Form.Label htmlFor="name" className="pl-4" size="large">
          Alert Name
        </System.Form.Label>
        <System.Form.Input
          id="name"
          name="name"
          placeholder="Enter a descriptive name for this alert profile"
          className="w-full"
          withContext
          required
          registerOptions={{
            validate: (value) => {
              return !isEmpty(value) || "This field is required"
            },
          }}
        />
        {errors.name && (
          <FieldInError className="ml-4">{errors.name.message}</FieldInError>
        )}
      </div>

      <div className="mb-8 pl-4">
        <System.Title className="mb-2 mt-8">Be alerted when...</System.Title>
        <System.Text>Select the conditions that trigger alerts:</System.Text>
      </div>
      <div className="grid grid-cols-2 gap-8 2xl:max-w-[80%]">
        <div className="rounded-lg border">
          <System.Content.Header withIcon className="border-b !py-4">
            <System.IconBullet icon="Monitoring" size="md" />
            <div>
              <System.Title>Monitoring Management</System.Title>
              <System.Text>
                Stay on top of your site's uptime, performance and visual
                consistency
              </System.Text>
            </div>
          </System.Content.Header>
          <div className="space-y-3 p-8 pl-9">
            {ELEMENTS.MONITORING.map((element) => {
              return (
                <div key={element.value} className="flex items-start gap-4">
                  <System.Form.Input
                    id={`eventTypes-${element.value}`}
                    type="checkbox"
                    name="eventTypes"
                    value={element.value}
                    className="mt-1.5"
                    withContext
                    registerOptions={{
                      validate: atLeastOneChecked,
                    }}
                  />
                  <div>
                    <System.Form.Label
                      htmlFor={`eventTypes-${element.value}`}
                      bold="medium"
                    >
                      {element.label}
                    </System.Form.Label>
                    <System.Text>{element.description}</System.Text>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="rounded-lg border">
          <System.Content.Header withIcon className="border-b !py-4">
            <System.IconBullet icon="Shield" size="md" />
            <div>
              <System.Title>Security Management</System.Title>
              <System.Text>
                Enhance your site's security with prompt vulnerability alerts
              </System.Text>
            </div>
          </System.Content.Header>
          <div className="space-y-3 p-8 pl-9">
            {ELEMENTS.SECURITY.map((element) => {
              return (
                <div key={element.value} className="flex items-start gap-4">
                  <System.Form.Input
                    id={`eventTypes-${element.value}`}
                    type="checkbox"
                    name="eventTypes"
                    value={element.value}
                    withContext
                    className="mt-1.5"
                    registerOptions={{
                      validate: atLeastOneChecked,
                    }}
                  />
                  <div>
                    <System.Form.Label
                      htmlFor={`eventTypes-${element.value}`}
                      bold="medium"
                    >
                      {element.label}
                    </System.Form.Label>
                    <System.Text>{element.description}</System.Text>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="rounded-lg border">
          <System.Content.Header withIcon className="border-b !py-4">
            <System.IconBullet icon="Shield" size="md" />
            <div>
              <System.Title>Backup & Restoration Management</System.Title>
              <System.Text>
                All what you should know about your backups & restoration status
              </System.Text>
            </div>
          </System.Content.Header>
          <div className="space-y-3 p-8 pl-9">
            {ELEMENTS.BACKUP.map((element) => {
              return (
                <div key={element.value} className="flex items-start gap-4">
                  <System.Form.Input
                    id={`eventTypes-${element.value}`}
                    type="checkbox"
                    name="eventTypes"
                    value={element.value}
                    className="mt-1.5"
                    withContext
                    registerOptions={{
                      validate: atLeastOneChecked,
                    }}
                  />
                  <div>
                    <System.Form.Label
                      htmlFor={`eventTypes-${element.value}`}
                      bold="medium"
                    >
                      {element.label}
                    </System.Form.Label>
                    <System.Text>{element.description}</System.Text>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
        <div className="rounded-lg border">
          <System.Content.Header withIcon className="border-b !py-4">
            <System.IconBullet icon="Monitoring" size="md" />
            <div>
              <System.Title>Update Management</System.Title>
              <System.Text>
                Keep track of safe updates process and automatic restorations.
              </System.Text>
            </div>
          </System.Content.Header>
          <div className="space-y-3 p-8 pl-9">
            {ELEMENTS.PLUGIN.map((element) => {
              return (
                <div key={element.value} className="flex items-start gap-4">
                  <System.Form.Input
                    id={`eventTypes-${element.value}`}
                    type="checkbox"
                    name="eventTypes"
                    value={element.value}
                    className="mt-1.5"
                    withContext
                    registerOptions={{
                      validate: atLeastOneChecked,
                    }}
                  />
                  <div>
                    <System.Form.Label
                      htmlFor={`eventTypes-${element.value}`}
                      bold="medium"
                    >
                      {element.label}
                    </System.Form.Label>
                    <System.Text>{element.description}</System.Text>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="mt-8">
        {errors.eventTypes && (
          <FieldInError className="mb-4">
            {errors.eventTypes.message}
          </FieldInError>
        )}
        <div className="flex items-center gap-2">
          <System.Button.Default href="/notifications">
            <System.Svg.ChevronLeft className="h-4 w-4 opacity-50" />
            Go back
          </System.Button.Default>
          <System.Button.Primary
            size="large"
            onClick={async () => {
              const validateName = await trigger("name")
              if (!validateName) {
                return
              }

              const validateEventTypes = await trigger("eventTypes")
              if (!validateEventTypes) {
                return
              }

              setCurrentStep({
                key: KEY_STEP_CREATE_NOTIFICATION_FORM,
                current: STEP_CHOOSE_CHANNEL,
                data: getValues(),
              })
            }}
          >
            Next
            <System.Svg.ChevronRight className="h-4 w-4 opacity-50" />
          </System.Button.Primary>
        </div>
      </div>
    </>
  )
}
