import { NotifcationActionComponents } from "./Action"
import { ExistingNotification } from "./ExistingNotification"
import { FetchAlertingNotifications } from "./Fetch"
import { AlertingNotificationFormComponents } from "./Form"
import { NotificationModals } from "./Modal"
import { AlertNoticationTableComponents } from "./Table"

export const NotificationComponents = {
  Action: NotifcationActionComponents,
  Fetch: FetchAlertingNotifications,
  Form: AlertingNotificationFormComponents,
  Modal: NotificationModals,
  Table: AlertNoticationTableComponents,
  ExistingNotification: ExistingNotification,
}
