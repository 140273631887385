import { useFieldArray, useFormContext } from "react-hook-form"

import { System } from "@design-system/index"
import React, { useEffect } from "react"
import { useGenericStep } from "@design-system/Context/GenericStep/hooks/useGenericStep"
import { CreateNotificationFormInputs } from "../../../types"
import { get, isEmpty } from "lodash"
import useUser from "@hooks/user/useUser"
import { KEY_STEP_CREATE_NOTIFICATION_FORM } from "../../../constants/step-create-notification"
import FieldInError from "@components/Forms/components/FieldInError"

const isNotEmpty = (value) => {
  return isEmpty(value) ? "This field is required" : true
}

export const EmailChannel = () => {
  const { getCurrentData } = useGenericStep()

  const {
    getValues,
    control,
    formState: { errors },
  } = useFormContext<CreateNotificationFormInputs>()
  const { fields, append, remove } = useFieldArray({
    control,
    //@ts-ignore
    name: "channels.emailChannel.emails",
  })

  const data = getCurrentData({
    key: KEY_STEP_CREATE_NOTIFICATION_FORM,
  })
  const { mainUser } = useUser()

  useEffect(() => {
    const firstEmail =
      data?.emailChannel?.emails[0] ?? get(mainUser, "email", "")

    const emails = getValues("channels.emailChannel.emails")
    if (emails.length === 0 || get(emails, "[0]") === "") {
      append(firstEmail)
    }
  }, [])

  return (
    <div className="min-w-[25%]">
      <div className="ml-4">
        <System.Title className="mb-2">Send to</System.Title>
        <System.Text>Specify recipients for the alerts:</System.Text>
      </div>

      <div className="mt-4">
        <>
          {fields.map((item, index) => {
            return (
              <div key={index}>
                <div className="relative mt-2 flex items-center">
                  <System.Svg.Email className="absolute left-4 top-1/2 z-50 h-5 w-5 -translate-y-1/2 opacity-50" />
                  <System.Form.Input
                    placeholder="Enter your email"
                    type="email"
                    name={`channels.emailChannel.emails.${index}`}
                    className="w-full pl-11"
                    withContext
                    registerOptions={{
                      validate: isNotEmpty,
                    }}
                  />
                  {index > 0 && (
                    <System.Button.Default
                      iconOnly
                      className="flex-none"
                      onClick={() => {
                        remove(index)
                      }}
                    >
                      <System.Svg.Trash className="h-5 w-5 opacity-50" />
                    </System.Button.Default>
                  )}
                </div>
                {errors?.channels?.emailChannel?.emails?.[index] && (
                  <FieldInError className="ml-4">
                    {errors.channels.emailChannel?.emails?.[index].message}
                  </FieldInError>
                )}
              </div>
            )
          })}
        </>
      </div>

      <div className="mt-4 flex items-center">
        <System.Button.Default
          className="underline"
          onClick={() => {
            append("")
          }}
        >
          <System.Svg.Plus className="h-4 w-4 opacity-50" />
          Add new E-mail
        </System.Button.Default>
        {/*
                @TODO: Need to wait workspace
                <System.Text size="small">or</System.Text>
                <div className="flex items-center">
                    <System.Button.Default className="underline">
                        Add all Team Members{" "}
                    </System.Button.Default>
                    <System.Text as="span" className="-ml-2">
                        (20)
                    </System.Text>
                </div> */}
      </div>
    </div>
  )
}
