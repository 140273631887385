import { JSONAlertNotification } from "@/AlertingNotification/types/alert-notification-models"
import { System } from "@design-system/index"
import { AlertNotificationRow } from "./Row"

interface Props {
  data: JSONAlertNotification[]
}

const COLUMNS = [
  {
    key: "name",
    name: "Name",
  },
  {
    key: "channels",
    name: "Channels",
  },
  {
    key: "action",
    name: "",
  },
]

export const AllAlertNotifications = ({ data }: Props) => {
  return (
    <System.Table.List<JSONAlertNotification>
      columns={COLUMNS}
      rows={data}
      columnsClassname="grid-cols-[1fr_1fr_1fr]"
    >
      {({ item, columnsClassname }) => {
        return (
          <AlertNotificationRow
            item={item}
            columnsClassname={columnsClassname}
          />
        )
      }}
    </System.Table.List>
  )
}
