import { createNotification } from "./createNotification"
import { toggleNotification } from "./toggleNotification"
import { linkSites } from "./linkSites"
import { unlinkSites } from "./unlinkSites"
import { deleteNotification } from "./deleteNotification"
import { editNotification } from "./editNotification"

export const FetcherAlertingNotification = {
  createNotification: createNotification,
  toggleNotification: toggleNotification,
  deleteNotification: deleteNotification,
  editNotification: editNotification,
  linkSites: linkSites,
  unlinkSites: unlinkSites,
}
