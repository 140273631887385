import { getFetchPrivateHeaders } from "@/Core/Fetcher/getFetchPrivateHeaders"
import { getPrivateApiAlertToggleOneNotificationPath } from "../api/routes-private"

interface Props {
  notificationId: string
}

export const toggleNotification = async ({ notificationId }: Props) => {
  const headers = await getFetchPrivateHeaders()
  const result = await fetch(
    getPrivateApiAlertToggleOneNotificationPath({
      notificationId,
    }),
    {
      method: "PUT",
      headers: headers,
    },
  )

  return await result.json()
}
