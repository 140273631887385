import { createSchema } from "morphism"

import {
  JSONMorphismOneAlertNotification,
  JSONOneAlertNotification,
} from "../types/alert-notification-models"
import { $Enums, AssignSelection } from "@prisma/client"
import { hasBackupEvent } from "./functions/hasBackupEvent"
import { hasSecurityEvent } from "./functions/hasSecurityEvent"
import { hasPerformanceEvent } from "./functions/hasPerformanceEvent"
import { isNil } from "lodash"

export const SchemaJSONOneAlertNotificationApiDataToAlertNotificationModel =
  createSchema<
    Omit<
      OneAlertNotificationModel,
      | "hasBackupEvent"
      | "hasSecurityEvent"
      | "hasPerformanceEvent"
      | "hasSlackChannel"
      | "hasEmailChannel"
    >,
    JSONMorphismOneAlertNotification
  >({
    id: "id",
    name: "name",
    is_active: "is_active",
    EmailNotificationChannel: "EmailNotificationChannel",
    SlackNotificationChannel: "SlackNotificationChannel",
    eventTypes: "eventTypes",
    site_assignment: "site_assignment",
  })

class OneAlertNotificationModel implements JSONOneAlertNotification {
  site_assignment: AssignSelection
  name: string
  id: string
  is_active: boolean
  EmailNotificationChannel?: {
    emails: string[]
  }
  SlackNotificationChannel?: {
    webhook: string
    icon?: string
    username?: string
  }
  eventTypes: $Enums.NotificationEventType[]

  hasEmailChannel(): boolean {
    return !isNil(this.EmailNotificationChannel)
  }

  hasSlackChannel(): boolean {
    return !isNil(this.SlackNotificationChannel)
  }

  hasBackupEvent(): boolean {
    return hasBackupEvent({
      eventTypes: this.eventTypes,
    })
  }

  hasSecurityEvent(): boolean {
    return hasSecurityEvent({
      eventTypes: this.eventTypes,
    })
  }

  hasPerformanceEvent(): boolean {
    return hasPerformanceEvent({
      eventTypes: this.eventTypes,
    })
  }
}

export default OneAlertNotificationModel
